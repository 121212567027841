
import { EventEduActions } from "@/store/vm/event-edu/enums";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import moment from "moment-timezone";
import Zoom from "@/views/event-edu/child/ZoomInformation.vue";

export default defineComponent({
  name: "eventEdu-list",
  components: {
    Zoom,
  },
  emits: ["del"],
  data() {
    return {
      loadingModule: true,
    };
  },
  setup(props, { emit }) {
    const store = useStore();
    const checked = ref(false);
    const { t } = useI18n();

    onMounted(() => {
      store.dispatch(EventEduActions.GET_ALL_EVENT_EDU).catch((e) => {
        console.log(e);
      });
    });

    const list = computed(() => {
      return store.state.EventEduModule.list;
    });

    const count = computed(() => {
      return store.state.EventEduModule.list.length;
    });

    const delEventEdu = (eventEduId) => {
      emit("del", eventEduId);
    };

    const lang = {
      edit: t("common.edit"),
      delete: t("common.delete"),
    };

    const hasMeeting = (meetingStr) => {
      const meeting = JSON.parse(meetingStr);
      if (Object.keys(meeting).length > 0) return meeting;
      return false;
    };

    const labelType = [
      {
        value: 0,
        label: "Sự kiện online",
      },
      {
        value: 1,
        label: "Sự kiện offline",
      },
      {
        value: 2,
        label: "Sự kiện Cross online + offline",
      },
    ];

    const labelStatus = [
      {
        value: 0,
        label: "Không công khai (chưa hiển thị trên App)",
      },
      {
        value: 1,
        label: "Công khai (hiển thị trên App)",
      },
      {
        value: 2,
        label: "Ẩn - sau khi sự kiện kết thúc, không hiển thị trên App",
      },
    ];

    const getLabelStatus = (statusId) => {
      return labelStatus.filter((m) => m.value === parseInt(statusId))[0][
        "label"
      ];
    };

    const getLabelType = (statusId) => {
      return labelType.filter((m) => m.value === parseInt(statusId))[0][
        "label"
      ];
    };

    return {
      list,
      checked,
      count,
      delEventEdu,
      lang,
      moment,
      hasMeeting,
      labelType,
      getLabelType,
      labelStatus,
      getLabelStatus,
    };
  },
});
