
import { EventEduActions } from "@/store/vm/event-edu/enums";
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { StepResult } from "@/store/captain/CaptainModule";
import { hideModal } from "@/core/helpers/dom";
import moment from "moment-timezone";

export default defineComponent({
  name: "zoom-information",
  props: {
    meeting: {
      type: String,
      required: true,
    },
    eventId: {
      type: Number,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const meetingObj = ref(JSON.parse(props.meeting));
    const hasMeeting = ref<boolean>(Object.keys(meetingObj.value).length > 0);

    const endTime = moment(String(props.endTime));
    var now = moment(Date.now());
    const hasExpired = ref<boolean>(endTime.isBefore(now));

    // watch prop
    const valueProp = computed(() => {
      return props.meeting;
    });

    watch(valueProp, (newVal) => {
      meetingObj.value = JSON.parse(newVal);
      hasMeeting.value = Object.keys(meetingObj.value).length > 0;
    });

    const store = useStore();

    const createZoomMeetingAuto = (eventId) => {
      return store
        .dispatch(EventEduActions.CREATE_ZOOM_MEETING_AUTO, eventId)
        .catch((e) => {
          console.log(e);
        });
    };

    const createZoomMeetingManual = (
      eventId,
      zoomMeetingId,
      zoomMeetingPwd
    ) => {
      return store
        .dispatch(EventEduActions.CREATE_ZOOM_MEETING_MANUAL, {
          eventEduId: eventId,
          zoomMeetingId: zoomMeetingId,
          zoomMeetingPwd: zoomMeetingPwd,
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const delZoomMeeting = (eventId) => {
      return store
        .dispatch(EventEduActions.DEL_ZOOM_MEETING, eventId)
        .catch((e) => {
          console.log(e);
        });
    };

    const onDelZoomMeeting = (eventEduId) => {
      Swal.fire({
        title: "Bạn có muốn xóa Zoom Meeting này?",
        text: "Zoom ID và mật khẩu hiện tại sẽ trở nên vô hiệu",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await delZoomMeeting(eventEduId);
        }
      });
    };

    const modalCreateZoomId = "modalCreateZoomId" + "EventId" + props.eventId;

    const defaultData = {
      zoomMeetingId: "",
      zoomMeetingPwd: "",
    };

    const formData = ref({ ...defaultData });
    const CreatZoomMeetingManualModalRef = ref<null | HTMLElement>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult = await createZoomMeetingManual(
            props.eventId,
            formData.value.zoomMeetingId,
            formData.value.zoomMeetingPwd
          );

          loading.value = false;
          if (!stepResult.isSuccess) {
            Swal.fire({
              text: stepResult.data,
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            return false;
          } else {
            hideModal(CreatZoomMeetingManualModalRef.value);
          }
        }
      });
    };

    return {
      hasExpired,
      valueProp,
      meetingObj,
      hasMeeting,
      createZoomMeetingAuto,
      createZoomMeetingManual,
      delZoomMeeting,
      onDelZoomMeeting,
      modalCreateZoomId,
      defaultData,
      formData,
      submit,
      CreatZoomMeetingManualModalRef,
      formRef,
    };
  },
});
