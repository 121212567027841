
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/event-edu/List.vue";
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { EventEduActions, EventEduGetters } from "@/store/vm/event-edu/enums";

interface ComponentData {
  eventEduIdModal: number | boolean;
}
export default defineComponent({
  name: "eventEdu",
  components: {
    List,
  },
  data(): ComponentData {
    return {
      eventEduIdModal: false,
    };
  },
  methods: {
    onUpsert(eventEduId: number | boolean) {
      this.eventEduIdModal = eventEduId;
    },
    onReset() {
      this.eventEduIdModal = false;
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Sự kiện", ["Trực tuyến"]);
      MenuComponent.reinitialization();
    });

    const onDel = (eventEduId) => {
      const detail = store.getters[EventEduGetters.GET_BY_ID](eventEduId);

      Swal.fire({
        title: "Bạn muốn xóa sự kiện này?",
        text: detail.title,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await actionDelete(eventEduId);
        }
      });
    };

    const actionDelete = async (eventEduId) => {
      return store.dispatch(EventEduActions.DELETE, eventEduId).catch((e) => {
        console.log(e);
      });
    };

    return {
      onDel,
    };
  },
});
